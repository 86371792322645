<template>
  <div class="fleetDashboard" :class="'fleetDashboard--' + dashboardType">
    <div v-if="dashboardType === 'provider'" class="bookingDashboard bookingDashboard--list">
      <div class="bookingDashboard__header bookingDashboard__header--provider">
        <div class="bookingDashboard__title">Availability List</div>
        <router-link class="buttonCreate" to="flight-uploads/new">Create</router-link>
      </div>
      <div class="bookingDashboard__table">
        <div class="bookingDashboard__table-header">
          <div class="bookingDashboard__table-cell">Aircraft</div>
          <div class="bookingDashboard__table-cell">Departure</div>
          <div class="bookingDashboard__table-cell">Arrival</div>
          <div class="bookingDashboard__table-cell">Departure Date</div>
        </div>
        <div class="bookingDashboard__table-body">
          <vue-scroll :ops="ops">
            <div
              class="bookingDashboard__table-row"
              v-for="item of availabilities"
              :key="item.availabilityId"
            >
              <div class="bookingDashboard__table-cell aircraftNumber">{{item.aircraftNumber}}</div>
              <div class="bookingDashboard__table-cell aircraftModel">{{item.departure}}</div>
              <div class="bookingDashboard__table-cell aircraftModel">{{item.arrival}}</div>
              <div class="bookingDashboard__table-cell aircraftType">
                {{moment(item.departureDate).format("MMM DD, YYYY")}}
                <router-link class="bookingDashboard__table-detail" :to="'flight-uploads/'+ item.emptyLegId">click for details</router-link>
                <div class="fleetDashboard__list-item-remove js-remove-aircraft" @click="showModal(item.emptyLegId)">
                  <SvgIcon name="close"/>
                </div>
              </div>
            </div>
          </vue-scroll>
        </div>
      </div>
    </div>
<!--        <div class="fleetDashboard__inner">-->
<!--          <router-link class="fleetDashboard__button" to="/provider-dashboard/new-aircraft">-->
<!--            <span class="text">Add new aircraft</span>-->
<!--          </router-link>-->
<!--          <div class="fleetDashboard__subtitle">Fleet</div>-->
<!--          <div v-if="!fleet.length" class="fleetDashboard__empty js-aircraft-empty">no aircraft</div>-->
<!--          <div class="fleetDashboard__list">-->
<!--            <div-->
<!--              v-for="item of fleet"-->
<!--              :key="item.id"-->
<!--              class="fleetDashboard__list-item js-aircraft-item"-->
<!--            >-->
<!--              <router-link-->
<!--                :to="'/provider-dashboard/fleet/' + item.id"-->
<!--                class="fleetDashboard__list-item-title"-->
<!--              >-->
<!--                <b>Aircraft {{item.counter}}: </b>{{item.title}}-->
<!--              </router-link>-->
<!--              <div class="fleetDashboard__list-item-remove js-remove-aircraft" @click="showModal(item.id)">-->
<!--                <SvgIcon name="close"/>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
    <QuestionPopup
      :name="'questionPopupFlight'"
      :title="'The aircraft availability will be removed'"
      :text="'Are you sure?'"
      @remove="remove"
    />
  </div>
</template>

<script>
  import { Tabs } from '@/js/plugins/tabs'
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import QuestionPopup from '@/components/common/modals/QuestionPopup'
  import '../../common/Fleet/Fleet.styl'
  import './FlightAvailabilitiesList.styl'
  import moment from 'moment'
  import {aircraftsAPI} from '@/api/aircrafts';

  export default {
    props: ['dashboardType'],
    name: 'Fleet',
    data: () => ({
      tabs: new Tabs('.js-tabNavItem', '.js-tabContentItem'),
      ops: {
        rail: {
          size: '3px',
        },
        bar: {
          size: '3px',
          background: 'rgba(0,0,0,0.1)',
        },
      },
    }),
    computed: {
      availabilities () {
        return this.$store.state.availabilities.flightAvailabilities
      },
    },
    async created () {
      await this.$store.dispatch('getFlightAvailabilities')
    },
    mounted () {
      this.tabs.addActiveForMouted()
    },
    methods: {
        moment,
      showModal (id) {
        this.$modal.show('questionPopupFlight')
        this.$store.commit('insertCurrentFleet', id)
      },
      async remove () {
        this.loading = true

        try {
          await aircraftsAPI.removeEmptyLegs({ emptyLegId: this.$store.state.fleet.currentFleet })

          window.location.reload();
        } catch (e) {
          console.log(e)
        }

        this.loading = false
      },
      tabActivate (event) {
        this.tabs.addActiveForClick(event.currentTarget)
      },
    },
    components: {
      SvgIcon,
      QuestionPopup,
    },
  }
</script>
